import axios from "axios";
import React, { useState } from "react";
import Spinner from "./Spinner";

const IndexSearch = () => {
  const [indexing, setIndexing] = useState(false);

  const handleReIndex = async () => {
    try {
      setIndexing(true);
      const response = await axios.post(
        `${process.env.REACT_APP_MAIN_SITE_URL}/api/search/index-data?secret=nextjs_templates_2024`
      );
      setIndexing(false);
      if (!response?.isError) {
        alert(response?.data?.message || "Data indexed successfully");
      }
    } catch (error) {
      setIndexing(false);
      console.log("error", error?.response);
      if (error?.response) {
        alert(
          error?.response?.data?.message ||
            "Something went wrong, please try again later"
        );
      }
    }
  };

  return (
    <div className="mt-5 border p-4">
      <h2 className="mb-2 font-heading text-4xl font-bold tracking-tighter text-black">
        Click the Re-index button to re-index the search data
      </h2>
      <p>
        only templates and blogs are re-indexed(add if data not exist or update
        the old data), static page are indexed only build time
      </p>
      <button
        style={{ cursor: indexing ? "not-allowed" : "pointer" }}
        disabled={indexing ? true : false}
        onClick={() => handleReIndex()}
        className="mt-3 btn btn-primary ms-auto me-auto"
      >
        Re-index {indexing && <Spinner />}
      </button>
    </div>
  );
};

export default IndexSearch;
